const {
    off
} = require("process");
import initSlider from '../../modules/customSlider/initSlider';

document.addEventListener('DOMContentLoaded', function () {

    /* Dynamic Banner Text */

    let titles = document.querySelectorAll(".first-top__title");
    let subTitles = document.querySelectorAll(".first-top__sub-title");


    titles.forEach(function (title) {
        lettering(title);
    });

    subTitles.forEach(function (subtitle) {
        lettering(subtitle);
    });

    animation()


    function lettering(element) {
        var text = element.textContent;
        element.innerHTML = "";

        for (var i = 0; i < text.length; i++) {
            var span = document.createElement("span");
            span.textContent = text[i];
            element.appendChild(span);
        }
    }

    function animation() {
        let titleSpans = document.querySelectorAll(".first-top__title span");
        let subTitleSpans = document.querySelectorAll(".first-top__sub-title span");

        titleSpans.forEach(function (span, index) {
            span.style.animationDelay = index * 0.08 + "s";
        });

        subTitleSpans.forEach(function (span, index) {
            span.style.animationDelay = index * 0.06 + "s";
        });
    }


    /* Slider */

    let initSlider = new Swiper('.top-slider', {
        loop: true,
        effect: "creative",
        speed: 1500,
        slideToClickedSlide: true,
        creativeEffect: {
            prev: {
                shadow: true,
                translate: ["0%", 0, -1],
            },
            next: {
                translate: ["100%", 0, 0],
            },
        },
        keyboard: {
            enabled: true,
        },
        autoplay: false,
        // autoplay: {
        //     delay: 9000,
        // },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        allowTouchMove: false,
    });

    performanceAnimation();
    platformsAnimation();
    platformsMobileAnimation();
    videcationAnimation();

    function performanceAnimation() {
        var elements;
        var windowHeight;

        function init() {
            elements = document.querySelectorAll('.performance__item');
            windowHeight = window.innerHeight;
        }

        function checkPosition() {
            for (var i = 0; i < elements.length; i++) {
                var element = elements[i];
                var positionFromTop = elements[i].getBoundingClientRect().top;

                if (positionFromTop - windowHeight <= 0) {
                    element.style.marginTop = "0px"
                }
            }
        }

        window.addEventListener('scroll', checkPosition);
        window.addEventListener('resize', init);

        init();
        checkPosition();
    };

    function platformsAnimation() {
        var elements;
        var windowHeight;

        function init() {
            elements = document.querySelectorAll('.platforms-item__content');
            windowHeight = window.innerHeight;
        }

        function checkPosition() {
            for (var i = 0; i < elements.length; i++) {
                var element = elements[i];
                var positionFromTop = elements[i].getBoundingClientRect().top;

                if (positionFromTop - windowHeight <= 0) {
                    element.classList.remove('platform-animate');
                }
            }
        }

        window.addEventListener('scroll', checkPosition);
        window.addEventListener('resize', init);

        init();
        checkPosition();
    };

    function platformsMobileAnimation() {
        var elements;
        var windowHeight;

        function init() {
            elements = document.querySelectorAll('.platforms-items__mobile');
            windowHeight = window.innerHeight;
        }

        function checkPosition() {
            for (var i = 0; i < elements.length; i++) {
                var element = elements[i];
                var positionFromTop = elements[i].getBoundingClientRect().top;

                if (positionFromTop - windowHeight <= 0) {
                    element.classList.remove('platform-animate');
                }
            }
        }

        window.addEventListener('scroll', checkPosition);
        window.addEventListener('resize', init);

        init();
        checkPosition();
    };

    function videcationAnimation() {
        var elements;
        var windowHeight;

        function init() {
            elements = document.querySelectorAll('.img-animation');
            windowHeight = window.innerHeight;
        }

        function checkPosition() {
            for (var i = 0; i < elements.length; i++) {
                var element = elements[i];
                var positionFromTop = elements[i].getBoundingClientRect().top;

                if (positionFromTop - windowHeight <= 0) {
                    element.classList.remove('img-animation');
                }
            }
        }

        window.addEventListener('scroll', checkPosition);
        window.addEventListener('resize', init);

        init();
        checkPosition();
    };

    /* Added Animation Number */
    function animateNumbers(element, targetNumber, duration) {
        const startNumber = 0;
        const interval = 50;
        const steps = duration / interval;
        const increment = (targetNumber - startNumber) / steps;

        let currentNumber = startNumber;
        const intervalId = setInterval(() => {
            currentNumber += increment;
            element.textContent = Math.round(currentNumber);
            if (currentNumber >= targetNumber) {
                clearInterval(intervalId);
            }
        }, interval);
    }

    function handleScroll() {
        const opportunitySection = document.querySelector('.opportunity');
        const opportunityCurrencyItems = document.querySelectorAll('.opportunity__currency-item');

        const clientsAccountsSection = document.querySelector('.clients-accounts');
        const clientsAccountsItems = document.querySelectorAll('.clients-accounts__item');

        if (!opportunitySection || !opportunityCurrencyItems.length) return;
        if (!clientsAccountsSection || !clientsAccountsItems.length) return;

        const opportunityTop = opportunitySection.offsetTop;
        const opportunityBottom = opportunityTop + opportunitySection.offsetHeight;

        const clientsAccountsTop = clientsAccountsSection.offsetTop;
        const clientsAccountsBottom = clientsAccountsTop + clientsAccountsSection.offsetHeight;

        const windowHeight = window.innerHeight;

        const animationDuration = 2000; // Set the duration to a higher value for slower animation

        if (window.scrollY + windowHeight >= opportunityTop && window.scrollY <= opportunityBottom) {
            opportunityCurrencyItems.forEach((item, index) => {
                const spanElement = item.querySelector('span[data-count]');
                const targetNumber = parseInt(spanElement.dataset.count, 10);
                if (!spanElement.hasAttribute('data-animated')) {
                    animateNumbers(spanElement, targetNumber, animationDuration + index * 200);
                    spanElement.setAttribute('data-animated', 'true');
                }
            });
        }
        if (window.scrollY + windowHeight >= clientsAccountsTop && window.scrollY <= clientsAccountsBottom) {
            clientsAccountsItems.forEach((item, index) => {
                const spanElement = item.querySelector('span[data-count]');
                const targetNumber = parseInt(spanElement.dataset.count, 10);
                if (!spanElement.hasAttribute('data-animated')) {
                    animateNumbers(spanElement, targetNumber, animationDuration + index * 200);
                    spanElement.setAttribute('data-animated', 'true');
                }
            });
        }
    }

    window.addEventListener('scroll', handleScroll);

    handleScroll();


    /* Slider reviews */

    const reviewsItems = document.querySelectorAll(".reviews__item");
    reviewsItems[0].classList.add("active");
    reviewsItems[1].classList.add("next");
    reviewsItems[reviewsItems.length - 1].classList.add("prev");


    const slider = document.querySelector(".reviews__items");
    const slides = document.querySelectorAll(".reviews__item");
    const button = document.querySelectorAll(".reviews__button");

    let current = 0;
    let prev = 14;
    let next = 1;

    for (let i = 0; i < button.length; i++) {
        button[i].addEventListener("click", () => i == 0 ? gotoPrev() : gotoNext());
    }

    const gotoPrev = () => current > 0 ? gotoNum(current - 1) : gotoNum(slides.length - 1);

    const gotoNext = () => current < 14 ? gotoNum(current + 1) : gotoNum(0);

    const gotoNum = number => {
        current = number;
        prev = current - 1;
        next = current + 1;

        for (let i = 0; i < slides.length; i++) {
            slides[i].classList.remove("active");
            slides[i].classList.remove("prev");
            slides[i].classList.remove("next");
        }

        if (next == 15) {
            next = 0;
        }

        if (prev == -1) {
            prev = 14;
        }

        slides[current].classList.add("active");
        slides[prev].classList.add("prev");
        slides[next].classList.add("next");
    }

});